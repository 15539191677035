/*
  Button

  October 20, 2017
  ask sorenfrederiksen@fairfaxmedia.com.au

  A series of mix-ins to help developers hastily apply standard button styles.

  Users are encouraged to stick to the standard button mixin ('button'), except where a given color
  scheme demands a change (use 'button--custom') or the function of the button necessitates one 
  (e.g. where the button deletes things, use 'button--destructive').

  All mix-ins result in buttons that can be made to appear deactivated (apply the '.is--deactivated'
  class to your button) or to signify the application is in the midst of loading (apply the 
  '.is--loading' class). The latter requires the inclusion of the SVG element include in the example
  HTML below.

  To use this button style, add this to the top of your HTML document (or add the symbol elements to 
  an existing svg container):

  ```
  <svg xmlns="http://www.w3.org/2000/svg" style="position:absolute; width: 0; height: 0;">
    <symbol viewBox="0 0 40 40" id="icon-loading">
      <circle class="loading__icon__circle" opacity="0" cx="15.8" cy="15" r="15"></circle>
      <path d="M27.8084006,22.8842813 C29.5771785,20.6011257 30.6299412,17.7353365 
               30.6299412,14.6236613 C30.6299412,9.67647577 27.9688583,5.35081946 
               24,3" class="loading__icon__active-segment"></path>  
    </symbol>
  </svg>
  ```

  You can then use the button style as follows.

  Your SCSS:

  ```
  .yourButtonClass {
    @include button("medium", "dynamic", "solid");
  } 

  // ... or ...

  .yourCustomButtonClass {
    @include button--custom(#fff, red, "1px solid red", "large", "dynamic", "red");
  }

  // ... or ...

  .yourDestructiveButtonClass {
    @include button--destructive("large", "fixed", "solid--reversed");
  }
  ```
  Your HTML:

  ```
  <button class="yourButtonClass">
    <span>A long button</span>
    <svg viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" 
         xmlns:xlink="http://www.w3.org/1999/xlink" stroke="#096DD2" 
         stroke-width="3" fill="transparent" class="button-loading">
      <use 
        xmlns:xlink="http://www.w3.org/1999/xlink" 
        xlink:href="#icon-loading" 
        class="button-loading__icon" 
        transform="translate(6.5 6.5)">
    </svg>
  </button>
  ```
*/

// *---> Private mix-ins

/*
  Button

  @param { Color } bg - Color for the button's background
  @param { Color } hover-bg - Color for the button's background when user hovers over it
  @param { Color } active-bg - Color for the button's background when the user clicks or taps it
  @param { Color } color - Color for button's text
  @param { Color } hover-color - Color for button's text when user hovers over it
  @param { Color } active-color - Color for button's text when user clicks or taps it
  @param { String } size - String denoting size of button (e.g. 'small', 'medium', 'large')
  @param { String } size-rules - String denoting rules of how button will be sized ('fixed' or 
  'dynamic')
  @param { Color } outline - Color of button's outline when it is clicked 
  @param { Boolean } can-load - Boolean indicating whether button can enter the loading state
*/

@mixin _button(
  $bg,
  $hover-bg,
  $active-bg,
  $color,
  $hover-color,
  $active-color,
  $border,
  $size,
  $size-rules,
  $outline,
  $can-load
) {
  @include fonts__sans-serif;
  font-weight: bold;
  text-align: center;
  user-select: none;

  display: inline-block;
  position: relative;
  border-radius: 4px;

  cursor: pointer;

  transition: background-color 0.15s ease-in-out;

  // Size
  @if ($size-rules == 'fixed') {
    @if ($size == 'medium') {
      padding: 13px 32px;
      width: 136px;
      min-height: 32px;
    } @else if ($size == 'large') {
      padding: 15px 32px;
      width: 136px;
      min-height: 44px;
    }
    // There is no 'fixed' style for 'small', only dynamic
  } @else {
    @if ($size == 'medium') {
      padding: 13px 32px;
    } @else if ($size == 'large') {
      padding: 15px 32px;
    }
    // See above for 'Where is small?'
  }

  @if ($size == 'small') {
    padding: 7px 12px;
  }

  // Text
  @if ($size == 'small') {
    font-size: 13px;
  } @else if ($size == 'medium') {
    font-size: 13px;
    text-transform: uppercase;
    line-height: 1.3;
    letter-spacing: 1px;
  } @else if ($size == 'large') {
    font-size: 14px;
    text-transform: uppercase;
    line-height: 1.3;
    letter-spacing: 1px;
  }

  // Coloring
  background: $bg;
  color: $color;

  &:hover {
    background: $hover-bg;
    color: $hover-color;
  }

  &:active {
    background: $active-bg;
    color: $active-color;
  }

  // Border
  border: $border;

  // Outline
  outline: $outline;

  // Deactivated
  &.is--deactivated {
    @include _button--deactivated;
  }

  // Loading
  @if ($can-load) {
    &.is--loading {
      @include _button--loading;
    }
  }

  // Button text
  span {
    opacity: 1;
    transition: opacity 300ms ease;
    pointer-events: none;
  }

  // Button loading icon
  @if ($can-load) {
    .button-loading {
      stroke: $color;
      opacity: 0;
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(0deg);
      transition: opacity 300ms ease;
      pointer-events: none;
      max-height: 80%;
    }
  }
}

/*
  Button deactivated
*/

@mixin _button--deactivated() {
  background: $color__gray--light;
  color: $color__gray--dark;
  border: none;
  outline: none;
  cursor: initial;

  &:hover,
  &:active {
    background: $color__gray--light;
    color: $color__gray--dark;
    border: none;
  }
}

/*
  Button loading
*/

@mixin _button--loading() {
  outline: none;
  cursor: initial;

  span {
    opacity: 0;
  }
  .button-loading {
    opacity: 1;
    animation: loading-icon-spin 400ms linear infinite;
  }
  @keyframes loading-icon-spin {
    100% {
      transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
  }
}

// *---> Public mix-ins

/*
  Button
  
  @param { String } size - String describing size of button (e.g. 'small', 'medium', 'large')
  @param { String } size-rules - String describing rules determining size of button (e.g. 'fixed' or
  'dynamic')
  @param { String } style - String describing style of button (e.g. 'solid', 'solid--reversed', 
  'hollow' or 'hollow--reversed')
  @param { Boolean } can-load - Boolean indicating whether button can enter the loading state
*/

@mixin button(
  $size: 'medium',
  $size-rules: 'dynamic',
  $style: 'solid',
  $can-load: true
) {
  @if ($style == 'solid') {
    @include _button(
      $color__blue--medium-2,
      $color__blue--medium,
      $color__blue--dark,
      #fff,
      #fff,
      #fff,
      none,
      $size,
      $size-rules,
      initial,
      $can-load
    );
  }

  @if ($style == 'solid--reversed') {
    @include _button(
      #fff,
      $color__gray--very-light,
      $color__mischka,
      $color__blue--medium-2,
      $color__big-stone,
      $color__mine-shaft,
      none,
      $size,
      $size-rules,
      initial,
      $can-load
    );
  }

  @if ($style == 'hollow') {
    @include _button(
      transparent,
      $color__gray--very-light,
      $color__gray--light,
      $color__blue--medium-2,
      $color__blue--medium-2,
      $color__blue--medium,
      1px solid $color__blue--medium-2,
      $size,
      $size-rules,
      initial,
      $can-load
    );
  }

  @if ($style == 'hollow--reversed') {
    @include _button(
      transparent,
      $color__blue--medium-2,
      $color__blue--dark,
      #fff,
      #fff,
      #fff,
      1px solid $color__gray--light,
      $size,
      $size-rules,
      initial,
      $can-load
    );
  }
}

/*
  Custom button

  @param { Color } custom-background - Colour for the background of the button
  @param { Color } custom-color - Colour for the text of the button
  @param { String } custom-border - Short-form border property for the button (e.g. '1px solid red')
  @param { String } size - String describing size of button (e.g. 'small', 'medium', 'large')
  @param { String } size-rules - String describing rules determining size of button (e.g. 'fixed' or
  'dynamic')
  @param { Color } custom-outline - Colour for the outline (shown on click) of the button
  @param { Boolean } can-load - Boolean indicating whether button can enter the loading state
*/

@mixin button-custom(
  $custom-background,
  $custom-color,
  $custom-border,
  $size: 'medium',
  $size-rules: 'dynamic',
  $custom-outline: initial,
  $can-load: true
) {
  @include _button(
    $custom-background,
    darken($custom-background, 10),
    darken($custom-background, 20),
    $custom-color,
    $custom-color,
    $custom-color,
    $custom-border,
    $size,
    $size-rules,
    $custom-outline,
    $can-load
  );
}

/*
  Destructive button

  @param { String } size - String describing size of button (e.g. 'small', 'medium', 'large')
  @param { String } size-rules - String describing rules determining size of button (e.g. 'fixed' or
  'dynamic')
  @param { String } style - String describing style of button (e.g. 'solid' or 'solid--reversed')
*/

@mixin button-destructive(
  $size: 'medium',
  $size-rules: 'dynamic',
  $style: 'solid',
  $can-load: false
) {
  $custom-background: $color__red--medium;
  $custom-color: #fff;

  @if ($style == 'solid--reversed') {
    $custom-background: #fff;
    $custom-color: $color__red--medium;
  }

  @include button-custom(
    $custom-background,
    $custom-color,
    none,
    $size,
    $size-rules,
    $color__red--medium,
    $can-load
  );
}
