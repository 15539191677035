/*
  Break point

  Mix-in used to create standard Fairfax breakpoints.

  These mix-ins can be used like so:

  @includes('only', 'small') {
    
    .someMobileStyle {
      color: red;
    }

    // ... other styles that will only show within the 'small' break-point bracket
  }

  See the 'Media Queries' section of this for a deeper explanation of how these work:
  https://robots.thoughtbot.com/sasss-content-directive

  @param { String } type - String denoting type of break-point to be created
  @param { String } size - String denoting size of break-point to be created
*/

@mixin break-point($type, $size) {
  $small: 600px;
  $medium: 768px;
  $large: 1024px;
  $extra-large: 1140px; 

  @if ($type == "only" or $type == "including and below") and $size == "small" {
    @media only screen and (max-width: #{$medium - 1}) {
      @content;
    }
  }
  @else if $type == "at least" and $size == "small" {
    @media only screen and (min-width: #{$small}) {
      @content;
    }
  }
  @else if $type == "only" and $size == "medium" {
    @media only screen and (min-width: #{$medium}) and (max-width: #{$large - 1}) {
      @content;
    }
  }
  @else if $type == "including and below" and $size == "medium" {
    @media only screen and (max-width: #{$large - 1}) {
      @content;
    }
  }
  @else if $type == "at least" and $size == "medium" {
    @media only screen and (min-width: #{$medium}) {
      @content;
    }
  }
  @else if $type == "only" and $size == "large" {
    @media only screen and (min-width: #{$large}) and (max-width: #{$extra-large - 1}) {
      @content;
    }
  }
  @else if $type == "including and below" and $size == "large" {
    @media only screen and (max-width: #{$extra-large - 1}) {
      @content;
    }
  }
  @else if $type == "at least" and $size == "large" {
    @media only screen and (min-width: #{$large}) {
      @content;
    }
  }
  @else if ($type == "at least" or $type == "only") and $size == "extra large" {
    @media only screen and (min-width: #{$extra-large}) {
      @content;
    }
  }
  @else if $type == "including and below" and $size == "extra large" {
    @content;
  }
}