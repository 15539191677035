@import "src/index.scss";



.comingsoon{
  left: 0px; top: 0px; overflow: hidden; margin: 0px; padding: 0px; height: 100%; width: 100%; z-index: -999999; position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  h2{
    color: #fff;
  }

  img{
    position: absolute; margin: 0px; padding: 0px; border: none; width: 1531px; height: 999.735px; max-height: none; max-width: none; z-index: -999999; left: 0px; top: -115.368px;
  }
}