@import "src/index.scss";

.not-found-page {
  max-width: 1130px;
  padding: 48px 16px 16px 16px;
  margin: 0 auto;

  &__tag {
    //@include fonts__serif-heading;
    @include fonts__sans-serif;

    font-size: 1.3rem;
    font-weight: 700;
    color: $color__blood-red;

    margin-bottom: 32px;
  }

  h1 {
    font-size: 1.75rem;

    margin-bottom: 16px;
  }

  img {
    width: 220px;
    display: block;
    opacity: 0.8;
    margin: 0 0 0 auto;
  }

  p {
    color: $color__dove-gray;
    font-size: 1.3rem;

    em {
      font-style: italic;
    }
    a {
      text-decoration: none;
      color: $color__action;

      text-underline-position: under;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@include break-point("at least", "small") {
  .not-found-page {
    &__tag {
      line-height: 2;
      font-size: 1.75rem;
    }

    h1 {
      font-size: 2.5rem;
      line-height: 1.125;
      margin-bottom: 24px;
    }
  }
}
