
@import "src/index.scss";

#social-button-container{
  .social-button-container {
    @include social-button-container();
  }
  
  .social-button-container__button {
    @include social-button();
    outline: none;
    background: transparent;
    border: none;
    color: $color__action;

  }
}
