@import "src/index.scss";

//
// Mobile
// ------------------------------------------------------------------------

$color__footer: #f0f0f0;



.footer-container {
  background: $color__footer;

  .footer {

    max-width: 1180px;
    margin: 0 auto;

    padding: 0 20px;

    &__header {
      padding: 8px 0;
      text-align: center;
      border-bottom: 1px solid #cccccc;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      &__logo-container {
        flex-grow: 1;
        flex-basis: 100%;
        height: 48px;

        &__logo {
          display: inline-block;
          position: relative;
          height: 100%;

          // Footer header navigation logo image
          $logo-width-size-percentage: 0.75;

          &.is--theage {
            $the-age-logo-width: 284px;

            width: $the-age-logo-width * $logo-width-size-percentage;
          }

          &.is--smh {
            $smh-width: 202px;

            width: $smh-width * $logo-width-size-percentage;
          }

          &.is--brisbanetimes {
            $bt-width: 294px;

            width: $bt-width * $logo-width-size-percentage;
          }

          &.is--watoday {
            $wa-today-width: 178px;
            width: $wa-today-width * $logo-width-size-percentage;
          }

          &__image {
            height: 100%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }

          &__link {
            display: block;
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      &__social {
        flex-grow: 1;
        flex-basis: 100%;

        text-align: center;

        &__button {
          div {
            color: $color__action-on-dark;
          }
        }
      }
    }

    &__copyright {
      // @include fonts__sans-serif;
      color: rgb(90, 90, 90);
      padding: 16px 0;
      font-size: 12px;

      text-align: center;

      &__text {
        @include fonts__sans-serif;

        // font-size: 14px;
        // font-size: 0.8rem;

        margin-bottom: 16px;

        width: 100%;
        display: inline-block;
      }

      &__logo {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 36px;
        display: inline-block;
      }
    }
  }
}

//
// Desktop
// ------------------------------------------------------------------------

@include break-point('at least', 'small') {
  .footer-container {

    .footer {

      &__header {
        text-align: left;
        padding: 16px 0;

        &__logo-container {
          height: 56px;

          flex-basis: 50%;
        }

        &__social {
          text-align: right;
          margin: initial;

          flex-basis: 50%;
        }
      }

      &__copyright {
        text-align: left;
        padding: 24px 0;

        &:after {
          content: '';
          display: table;
          clear: both;
        }

        &__text {
          font-size: 13px;
          letter-spacing: 2.4px;
          // font-size: 1rem;

          line-height: 36px;

          margin-bottom: initial;
          width: initial;
          float: left;
        }

        &__logo {
          width: 64px;
          float: left;

          margin-left: 16px;
        }
      }
    }
  }
}