@import "src/index.scss";

.dynamic-import-loader {
  width: 100%;
  height: 100%;
  min-height: 90vh;

  position: relative;

  &__image {
    width: 150px;
    height: 150px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

//
// Tablet
// -----------------------------------------------------------

@include break-point("at least", "small") {
  .dynamic-import-loader {
    &__image {
      width: 220px;
      height: 220px;
    }
  }
}
