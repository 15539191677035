/*
  Colors

  November 9, 2017
  ask sorenfrederiksen@fairfaxmedia.com.au
*/

// *---> Fairfax colours

/*
  Real color names
*/

//                                      Color   | Lightness        | Shade information
$color__aluminium:          #a6acba; // Gray    - medium
$color__apple:              #43a047; // Green   - medium 
$color__athens-gray:        #f4f5f7; // Gray    - very light
$color__big-stone:          #192646; // Blue    - very dark        - navy, grayish
$color__blue-zodiac:        #0a1633; // Blue    - very dark        - navy
$color__blood-red:          #D0011B; // Red     - medium
$color__border-gray:        #949697; // Gray    - medium           
$color__congress-blue:      #004080; // Blue    - dark             - navy
$color__crimson:            #d9172f; // Red     - medium/dark
$color__dove-gray:          #707070; // Gray    - dark
$color__egg-white:          #fff1c7; // Orange  - very light
$color__endeavour:          #0053a6; // Blue    - medium           - navy
$color__japanese-laurel:    #008000; // Green   - medium/dark
$color__malibu:             #58b5ff; // Blue    - light/medium
$color__mine-shaft:         #232323; // Black   - medium, charcoal
$color__mischka:            #d7dbe3; // Gray    - light
$color__persimmon:          #ff6347; // Orange  - medium           - reddish
$color__pizazz:             #f16334; // Orange  - light/medium
$color__river-bed:          #454f64; // Blue    - medium/dark      - very grayish
$color__science-blue:       #096dd2; // Blue    - light/medium

/*
  Descriptive color names
*/

$color__gray--very-light:   $color__athens-gray;
$color__gray--light:        $color__mischka;
$color__gray--medium:       $color__aluminium;
$color__gray--medium-dark:  $color__border-gray;
$color__gray--dark:         $color__dove-gray;

$color__green--medium:      $color__apple;
$color__green--dark:        $color__japanese-laurel;

$color__blue--light:        $color__malibu;
$color__blue--medium:       $color__endeavour;
$color__blue--medium-2:     $color__science-blue;
$color__blue--medium-3:     $color__river-bed;
$color__blue--dark:         $color__congress-blue; 
$color__blue--very-dark:    $color__blue-zodiac;
$color__blue--very-dark-2:  $color__big-stone;

$color__red--medium:        $color__crimson;
$color__red--medium-2:      $color__blood-red;

$color__orange--very-light: $color__egg-white;
$color__orange--light:      $color__pizazz;
$color__orange--medium:     $color__persimmon;

$color__black--medium:      $color__mine-shaft;

/*
  Functional color names
*/

$color__input-border-gray:  $color__border-gray;
$color__border-red:         $color__red--medium-2;
$color__brand:              $color__blue-zodiac;
$color__brand-offset:       $color__big-stone;
$color__action:             $color__science-blue;
$color__action-dark:        $color__endeavour;
$color__action-darkest:     $color__congress-blue;
$color__action-on-dark:     $color__malibu;
$color__highlight:          $color__crimson;
$color__highlight-offset:   $color__persimmon;
$color__dark:               $color__mine-shaft;
$color__mid:                $color__dove-gray;
$color__dark-accent:        $color__river-bed;
$color__mid-accent:         $color__aluminium;
$color__light-accent:       $color__mischka;
$color__light-fill:         $color__athens-gray;
$color__orange:             $color__pizazz;
$color__orange-light-fill:  $color__egg-white;
$color__green:              $color__apple;
$color__green-dark:         $color__japanese-laurel;        
