/*
  Fonts

  The file assumes it will be compiled to a CSS file that sits like so in the project's directory 
  hierarchy:

  main.css
  .. / assets
  .. .. / fonts
  .. .. .. / abril-titling
  .. .. .. .. / AbrilTitling-Semibold.woff
  .. .. .. .. / AbrilTitling-Semibold.woff2
  .. .. .. / pt-sans
  .. .. .. .. / PTSans-Regular.woff
  .. .. .. .. / PTSans-Regular.woff2
  .. .. .. .. / PTSans-Bold.woff
  .. .. .. .. / PTSans-Bold.woff2
  .. .. .. / pt-serif
  .. .. .. .. / PTSerif-Regular.woff
  .. .. .. .. / PTSerif-Regular.woff2
  .. .. .. .. / PTSerif-Bold.woff
  .. .. .. .. / PTSerif-Bold.woff2
*/

// Font faces

@mixin fonts($targetSkeleton) {
  $fontPrefix: '../assets/fonts';

  @if ($targetSkeleton == svelte) {
    $fontPrefix: '../fonts';
  } @else if ($targetSkeleton == react) {
    $fontPrefix: 'utils/fonts';
  }

  /*
    Font family: Abril Titling
  */
  @font-face {
    font-family: 'Abril Titling Bold';
    font-weight: 700;
    font-style: normal;

    font-display: swap; /* https://css-tricks.com/really-dislike-fout-font-display-optional-might-jam */
    src: url($fontPrefix+'/abril-titling/AbrilTitling-Semibold.woff2') format('woff2'),
      url($fontPrefix+'/abril-titling/AbrilTitling-Semibold.woff') format('woff');
  }

  @font-face {
    font-family: 'Abril Titling Bold';
    font-weight: 700;
    font-style: italic;

    font-display: swap;
    src: url($fontPrefix+'/abril-titling/AbrilTitling-Semibold-italic.woff2') format('woff2'),
      url($fontPrefix+'/abril-titling/AbrilTitling-Semibold-italic.woff') format('woff');
  }

  /*
    Font family: PT Sans
    Source: https://fonts.google.com/specimen/PT+Sans
  */
  @font-face {
    font-family: 'PT Sans';
    font-weight: 400;
    font-style: normal;

    font-display: fallback;

    src: url($fontPrefix+'/pt-sans/PTSans-Regular.woff2') format('woff2'),
      url($fontPrefix+'/pt-sans/PTSans-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'PT Sans';
    font-weight: 700;
    font-style: normal;

    font-display: fallback;
    src: url($fontPrefix+'/pt-sans/PTSans-Bold.woff2') format('woff2'),
      url($fontPrefix+'/pt-sans/PTSans-Bold.woff') format('woff');
  }

  /*
    Font family: PT Serifs
    Source: https://fonts.google.com/specimen/PT+Serif;
  */
  @font-face {
    font-family: 'PT Serif';
    font-weight: 400;
    font-style: normal;

    font-display: fallback;
    src: url($fontPrefix+'/pt-serif/PTSerif-Regular.woff2') format('woff2'),
      url($fontPrefix+'/pt-serif/PTSerif-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'PT Serif';
    font-weight: 700;
    font-style: normal;

    font-display: fallback;
    src: url($fontPrefix+'/pt-serif/PTSerif-Bold.woff2') format('woff2'),
      url($fontPrefix+'/pt-serif/PTSerif-Bold.woff') format('woff');
  }
}

// Font stacks

@mixin fonts__serif() {
  font-family: 'PT Serif', Georgia, Times, 'Times New Roman', serif;
}

@mixin fonts__sans-serif() {
  font-family: 'PT Sans', 'Trebuchet MS', 'Arial Narrow', sans-serif;
}

@mixin fonts__serif-heading() {
  font-family: 'Abril Titling Bold', 'Book Antiqua', Palatino, 'Palatino Linotype', 'Palatino LT STD', Georgia, serif;
}
