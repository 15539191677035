@import "src/index.scss";

* {
	padding: 0;
	margin: 0;
}


body {
	//background-color: LavenderBlush;
	@include fonts__serif;
	font-size: 100%;
	line-height: 1.5;
	margin: 0;
	color: #3d3d3d;

	iframe{
		pointer-events: none;
	}

}


/* Define the scrollbar track */
::-webkit-scrollbar {
	width: 12px; /* Width of the scrollbar */
  }
  
  /* Define the scrollbar thumb (the draggable part) */
  ::-webkit-scrollbar-thumb {
	background-color: #f16334; /* Change this to your desired color */
	border-radius: 4px; /* Rounded corners for the thumb */
  }
  
  /* Define the scrollbar track when not hovered */
  ::-webkit-scrollbar-track {
	background-color: #f1f1f1; /* Change this to your desired color */
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
  }
  
  /* Define the scrollbar thumb when hovered */
  ::-webkit-scrollbar-thumb:hover {
	background-color: #f16334; /* Change this to your desired color on hover */
  }




*,
::after,
::before {
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
	//@include fonts__serif-heading;
	@include fonts__sans-serif;
}

p {
	//@include fonts__serif-heading;
	@include fonts__sans-serif;
}




a {
	color: $color__orange;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

figure{
	margin-bottom: 1rem;
}



// .horizontal-divider {
// 	display: inline-block;
// 	border-top: 2px solid $color__orange;
// 	width: 100px;
// 	height: 2px;
// }

ol, ul{
	padding-left: calc(2 * 10px);
    margin-bottom: 1.5rem;
	list-style-type: disc;
	li{
		@include fonts__serif;
		display: list-item;
		font-size: 18px;
		font-size: 1.125rem;
		line-height: 1.7777777778;
	}
}
 
hr{
	margin:1rem 0
}

.preloader{
	width: 100%;
	height: 100vh;
	background-color: green;
}

.section-container {
	width: 100%;

	.section-header {
		position: relative;
		width: 100%;
		min-height: 370px;
		background-color: rgb(161, 161, 161);
		background-position: center center;
		background-size: cover;

		@include break-point('at least', 'medium') {
			min-height: 460px;
		}

		&__overlay {
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			position: absolute;
			//background-color: #f4f4f4;
			background-color: rgb(0, 0, 0);
			opacity: 0.3;
		}

		.header-contents {
			min-height: 200px;
			padding: 20px;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			//position: absolute;
			z-index: 2;

			@include break-point('at least', 'medium') {
				min-height: 460px;
			}

			&__holder {
				// @include fonts__serif-heading;
				width: 100%;
				max-width: 620px;
				//padding: 10px;
				margin: 0 auto;
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				@include break-point('at least', 'medium') {
					padding: 0;
				}

				h1,
				h2,
				h5 {
					margin: 15px 0;
					padding: 0;
				}

				// p {
				// 	@include fonts__sans-serif;
				// 	padding: 0;
				// 	margin: 0;

				// }

			}
		}
	}


	.section-wrapper {

		width: 100%;
		max-width: 1180px;
		margin: 0 auto;
		padding: 1rem;
		margin: 1.5rem auto;

		.section-title {
			@include fonts__sans-serif;
			font-weight: 700;
			font-size: 1.8rem;

			&:before {
				content: '';
				border-top: 2px solid $color__orange;
				display: block;
				width: 120px;
			}
		}

		.content {
			display: flex;
			flex-direction: column;

			@include break-point('at least', 'medium') {
				flex-direction: row;
			}

			.header {
				min-height: 320px;
				background-color: pink;
			}

			&__main {

				width: 100%;
				padding-right: 0;

				@include break-point('at least', 'medium') {
					width: 66.6%;
					padding-right: 24px;
				}

				.item {
					width: 100%;
					padding: 2em 0;


					.item-details {
						display: flex;
						justify-content: space-between;
					}

					// .date {
					// 	// @include fonts__sans-serif;
					// 	font-size: 14px;
					// }

					h2 {
						@include fonts__sans-serif;

						// @include fonts__serif-heading;
						// font-size: 1.4rem;
						// line-height: 1.2em;
						// padding: 10px 0;
						// color: #2c2c2c;
						// //font-weight:700;

						// @include break-point('at least', 'medium') {
						// 	font-size: 1.8rem;
						// }
					}

					.excerpt {
						// @include fonts__serif;
						color: #3f3f3f;
						font-size: 17px;
						font-weight: 300;
						line-height: 1.3em;
						margin-bottom: 20px;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						-moz-box-orient: vertical;

						p {

							margin: 0;
						}
					}


					.btn-readmore {
						@include button;
					}

					img {
						width: 100%;
					}
				}

				.loading-more-icon{

					@include fonts__sans-serif;

					svg{
						vertical-align: middle;
    					margin-left: 8px;
					}
					
				}
			}

			&__side {
				//background-color: #f9f9f9;
				width: 100%;
				padding-left: 24px;
				border-left: 1px solid #f0f0f0;
				@include break-point('at least', 'medium') {
					width: 33.4%;
				}
			}

		}

	}
}